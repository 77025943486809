.swiper {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.swiper-slide {
  text-align: center;
  font-size: 11px;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgContainer {
  position: relative;
  width: 100%;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.imgDetails {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1000 !important;
}

.imgDetails:hover {
  transition: .5s ease;
  opacity: 0.7 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1000 !important;
}

.react-aspect-ratio-placeholder {
  background-color: rgb(0, 0, 0, 0);
}

.deleteIcon {
  height: 20%;
  background-color: rgb(224, 224, 224, 0.4);
  border-bottom: 2px solid rgb(224, 224, 224, 0.25);
}

.deleteIcon:active {
  background-color: rgb(224, 224, 224, 0.6);
}

.detailsIcon {
  top: '40%';
  height: 80%;
  background-color: rgb(245, 245, 245, 0.2);
}