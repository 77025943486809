.animated-graph {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s ease-in forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
    
@keyframes dash{
  to {
    stroke-dashoffset: 0;
  }
}

    