.layout-menu-container {
    max-height: 30vh !important;
    background-color: #002240;
}

.menu-container {
    width: 100%;
    height: 7vw;
    display: inline-flex;
    background-color: #002240;
    overflow: hidden;
}

.waterQ-logo-container {
    padding: 0% 0% 0% 5vw;
    width: 10vw;
    background-color: #002240;
    display: inline-flex;
    align-items: center;
}

.waterQ-logo {
    display: inline-flex;
    width: 100%;
    height: auto;
    cursor: pointer;
}

.select-dropdown-container {
    width: 10vw;
    padding-left: 5vw;
    margin: 1.5% auto;
}

.options-list {
    z-index: 99999;
    overflow: visible;
}

.tabs-container {
    margin: 2% auto;
    background-color: #002240;
    display: flex;
    align-items: center;
    transform: scale(1, 1.3);
    -webkit-transform: scale(1, 1.3);
    -moz-transform: scale(1, 1.3);
    -ms-transform: scale(1, 1.3);
    -o-transform: scale(1, 1.3);
}

.waterQ-project-details {
    display: inline-flex;
    cursor: pointer;
    color: #ffffff;
    background-color: #002240;
    padding: 0 0 0 5vw;
    font-family: "Oswald";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
}

.waterQ-project-details-selected {
    display: inline-flex;
    cursor: pointer;
    color: #00d1ff;
    background-color: #002240;
    padding: 0 0 0 5vw;
    font-family: "Oswald";
    font-weight: 400, "Regular";
    font-size: 1.2vw;
}

.waterQ-locations {
    display: inline-flex;
    cursor: pointer;
    color: #ffffff;
    background-color: #002240;
    padding: 0 0 0 5vw;
    font-family: "Oswald";
    font-weight: 400, "Regular";
    font-size: 1.2vw;
}

.waterQ-contacts {
    display: inline-flex;
    cursor: pointer;
    color: #ffffff;
    background-color: #002240;
    padding: 0 0 0 5vw;
    font-family: "Oswald";
    font-weight: 400, "Regular";
    font-size: 1.2vw;
}

.waterQ-contacts-selected {
    display: inline-flex;
    cursor: pointer;
    color: #00d1ff;
    background-color: #002240;
    padding: 0 0 0 5vw;
    font-family: "Oswald";
    font-weight: 400, "Regular";
    font-size: 1.2vw;
}

.language-selector-container {
    width: 15vw;
    display: block;
    margin: auto;
    cursor: pointer;
    background-color: #002240;
    color: #ffffff !important;
    padding: 0 0 0 5vw;
    font-family: "Oswald";
    font-weight: 400, "Regular";
    font-size: 1.2vw;
}

.select-language {
    cursor: default;
}

.language-list {
    display: inline-flex;
}

.croatian-flag {
    height: 2vw;
}

.croatian-lang-selected {
    color: #00d1ff;
}

.uk-flag {
    margin: 2.5% 0 0 20%;
    height: 1.5vw;
    width: 1.8vw;
}

.english-lang {
    padding-left: 2%;
}

.english-lang-selected {
    padding-left: 2%;
    color: #00d1ff;
}

.outer-logo-container {
    height: 100%;
    padding: 0% 2% 0% 5%;
    display: inline-flex;
    background-color: #002240;
    align-items: center;
}

.EU-logo {
    height: 3vw;
    width: auto;
}

.Linkedin-logo {
    height: 3vw;
    width: auto;
}

.YT-logo {
    height: 3vw;
    width: auto;
}

@media only screen and (min-width: 600px) and (max-width: 960px),
only screen and (min-device-width: 600px) and (max-device-width: 960px) and (orientation: portrait) {
    .menu-container {
        width: 100%;
        height: auto;
        max-height: 10vw;
        background-color: #002240;
        overflow: visible !important;
    }

    .waterQ-logo-container {
        padding: 0% 0% 0% 0%;
        width: 100%;
        background-color: #002240;
        display: inline-flex;
        align-items: center;
    }

    .waterQ-logo {
        width: 40%;
        margin: auto;
    }

    .tabs-container {
        display: flex;
        justify-content: space-around;
        width: 90%;
        padding: 0 5% 0 5%;
    }

    .waterQ-project-details {
        height: 100%;
        width: 14%;
    }

    .waterQ-project-details-selected {
        height: 100%;
        width: 14%;
    }

    .waterQ-locations {
        height: 100%;
        width: 14%;
    }

    .waterQ-locations-selected {
        height: 100%;
        width: 14%;
    }

    .waterQ-contacts {
        height: 100%;
        width: 14%;
    }

    .waterQ-contacts-selected {
        height: 100%;
        width: 14%;
    }

    .language-selector-container {
        width: 40%;
        padding-right: 5vw;
        font-size: 2.6vw;
    }

    .language-list {
        display: flex;
        justify-content: space-between;
        padding: 0 40% 0 0;
    }

    .croatian-lang-selected {
        color: #00d1ff;
    }

    .english-lang-selected {
        color: #00d1ff;
    }
}

@media only screen and (min-width: 350px) and (max-width: 599px),
only screen and (min-device-width: 350px) and (max-device-width: 599px) and (orientation: portrait) {
    .menu-container {
        width: 100%;
        height: auto;
        max-height: 13vw;
        background-color: #002240;
        overflow: visible !important;
    }

    .waterQ-logo-container {
        padding: 0% 0% 0% 0%;
        width: 100%;
        background-color: #002240;
        display: inline-flex;
        align-items: center;
    }

    .waterQ-logo {
        width: 50%;
        margin: auto;
    }

    .tabs-container {
        display: flex;
        justify-content: space-between;
        width: 50%;
        padding: 0 5% 0 5%;
    }

    .waterQ-project-details {
        height: 80%;
        width: 18%;
    }

    .waterQ-project-details-selected {
        height: 80%;
        width: 18%;
    }

    .waterQ-locations {
        height: 80%;
        width: 18%;
    }

    .waterQ-locations-selected {
        height: 80%;
        width: 18%;
    }

    .waterQ-contacts {
        height: 80%;
        width: 18%;
    }

    .waterQ-contacts-selected {
        height: 80%;
        width: 18%;
    }

    .language-selector-container {
        width: 40%;
        padding-right: 5vw;
        font-size: 3.2vw;
    }

    .language-list {
        display: flex;
        justify-content: space-between;
        padding: 0 40% 0 0;
    }

    .croatian-lang-selected {
        color: #00d1ff;
    }

    .english-lang-selected {
        color: #00d1ff;
    }
}

@media only screen and (min-width: 280px) and (max-width: 349px),
only screen and (min-device-width: 280px) and (max-device-width: 349px) and (orientation: portrait) {
    .menu-container {
        width: 100%;
        height: auto;
        max-height: 13vw;
        background-color: #002240;
        overflow: visible !important;
    }

    .waterQ-logo-container {
        padding: 0% 0% 0% 0%;
        width: 100%;
        background-color: #002240;
        display: inline-flex;
        align-items: center;
    }

    .waterQ-logo {
        width: 50%;
        margin: auto;
    }

    .tabs-container {
        display: flex;
        justify-content: space-between;
        width: 60%;
        padding: 0 5% 0 5%;
    }

    .waterQ-project-details {
        height: 80%;
        width: 18%;
    }

    .waterQ-project-details-selected {
        height: 80%;
        width: 18%;
    }

    .waterQ-locations {
        height: 80%;
        width: 18%;
    }

    .waterQ-locations-selected {
        height: 80%;
        width: 18%;
    }

    .waterQ-contacts {
        height: 80%;
        width: 18%;
    }

    .waterQ-contacts-selected {
        height: 80%;
        width: 18%;
    }

    .language-selector-container {
        width: 40%;
        padding-right: 5vw;
        font-size: 4vw;
    }

    .language-list {
        display: flex;
        justify-content: space-between;
        padding: 0 40% 0 0;
    }

    .croatian-lang-selected {
        color: #00d1ff;
    }

    .english-lang-selected {
        color: #00d1ff;
    }
}

@media only screen and (min-height: 250px) and (max-height: 550px),
only screen and (min-device-height: 250px) and (max-device-height: 550px) and (orientation: landscape) {
    .menu-container {
        width: 100%;
        height: auto;
        max-height: 11vw;
        background-color: #002240;
        overflow: visible !important;
    }

    .waterQ-logo {
        display: inline-flex;
        width: 20vh;
        height: auto;
        cursor: pointer;
    }

    .waterQ-logo-container {
        padding: 0% 0% 0% 0%;
        width: 100%;
        height: 10vh;
        background-color: #002240;
        display: inline-flex;
        align-items: center;
    }

    .tabs-container {
        display: flex;
        height: 5vh;
        justify-content: space-around;
        width: 70%;
        padding: 0 5% 0 5%;
    }

    .waterQ-project-details {
        height: 100%;
        width: 5%;
    }

    .waterQ-project-details-selected {
        height: 100%;
        width: 5%;
    }

    .waterQ-locations {
        height: 100%;
        width: 5%;
    }

    .waterQ-locations-selected {
        height: 100%;
        width: 5%;
    }

    .waterQ-contacts {
        height: 100%;
        width: 5%;
    }

    .waterQ-contacts-selected {
        height: 100%;
        width: 5%;
    }

    .language-selector-container {
        width: 40%;
        padding-right: 5vw;
        font-size: 1em;
    }

    .language-list {
        display: flex;
        justify-content: space-between;
        padding: 0 40% 0 0;
    }

    .croatian-lang-selected {
        color: #00d1ff;
    }

    .english-lang-selected {
        color: #00d1ff;
    }
}