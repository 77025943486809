.footer-main {
    background-color: #002240;
}

.footer-fund-collection {
    display: inline-flex;
    justify-content: space-around;
    padding: 2% 0% 2% 0%;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    align-items: center;
}

.footer-content {
    background-color: #002240;
    width: 100%;
    max-height: 100%;
    padding-bottom: 2%;
    overflow: hidden;
}

.footer-EU-logo {
    width: 10%;
}

.footer-MRRF-logo {
    width: 22%;
}

.footer-ESIF-logo {
    width: 16%;
}

.footer-OPPK-logo {
    width: 20%;
}

.footer-content-top {
    padding-top: 3%;
    display: inline-flex;
}

.footer-tabs-container {
    background-color: #002240;
    width: 45%;
    display: inline-flex;
    align-items: center;
    transform: scale(1, 1.3);
    -webkit-transform: scale(1, 1.3);
    -moz-transform: scale(1, 1.3);
    -ms-transform: scale(1, 1.3);
    -o-transform: scale(1, 1.3);
}

.project-manager-mail-mark {
    color: #08d4fc;
}

.project-manager-phone-mark {
    color: #08d4fc;
}

.footer-contact-personel {
    display: flex;
    padding: 2% 0% 0% 6%;
    justify-content: space-around;
}

.footer-project-manager {
    font-family: "Open Sans";
    font-weight: 400, "Regular";
    font-size: 1.1vw;
    line-height: calc(1.1em + 1vmin);
    color: #ffffff;
    padding-right: 5%;
}

.project-manager-title {
    font-weight: 900;
}

.footer-tehnical-project-manager {
    font-family: "Open Sans";
    font-weight: 400, "Regular";
    font-size: 1.1vw;
    line-height: calc(1.1em + 1vmin);
    color: #ffffff;
    padding-right: 5%;
}

.tehnical-project-manager-title {
    font-weight: 900;
}

.footer-commercial-issues {
    font-family: "Open Sans";
    font-weight: 400, "Regular";
    font-size: 1.1vw;
    line-height: calc(1.1em + 1vmin);
    color: #ffffff;
    padding-right: 5%;
}

.commercial-issues-title {
    font-weight: 900;
}

.footer-copyright {
    font-family: "Open Sans";
    font-weight: 400, "Regular";
    font-size: 1.1vw;
    line-height: 3vw;
    text-align: center;
    height: 50%;
    background-color: #08d4fc;
    color: #000000;
}

@media only screen and (min-width: 280px) and (max-width: 960px) {
    .footer-content {
        padding: 0px;
        height: 50%;
    }

    .footer-fund-collection {
        display: block;
        width: 100%;
        justify-content: space-between;
    }

    .footer-EU-logo {
        width: 40%;
    }

    .footer-MRRF-logo {
        width: 60%;
        padding-bottom: 5%;
    }

    .footer-ESIF-logo {
        width: 50%;
    }

    .footer-OPPK-logo {
        width: 50%;
    }

    .footer-waterQ-logo-container {
        width: 30%;
    }

    .footer-waterQ-logo {
        width: 100%;
    }

    .footer-tabs-container {
        visibility: hidden;
    }

    .footer-contact-personel {
        height: 100% !important;
        width: 100% !important;
        display: block;
    }

    .footer-project-manager {
        width: 100%;
        padding-top: 5%;
        font-size: calc(0.8em + 1vmin);
        line-height: calc(1.2em + 1vmin);
    }

    .footer-tehnical-project-manager {
        width: 100%;
        padding-top: 5%;
        font-size: calc(0.8em + 1vmin);
        line-height: calc(1.2em + 1vmin);
    }

    .footer-commercial-issues {
        width: 100%;
        padding-top: 5%;
        font-size: calc(0.8em + 1vmin);
        line-height: calc(1.2em + 1vmin);
    }

    .footer-copyright {
        font-size: calc(0.8em + 1vmin);
        line-height: calc(1.2em + 1vmin);
        height: 27%;
        margin-top: 30px;
        padding: 2%;
    }
}
