@keyframes intro-1 {
    from {
        transform: 0;
    }
    to {
        transform: translateY(-10vw);
        transition-delay: 1s;
    }
}

@keyframes intro-2 {
    from {
        transform: 0;
    }
    to {
        transform: rotate(-50deg) translateY(-10vw) rotate(50deg);
        transition-delay: 2s;
    }
}

@keyframes intro-3 {
    from {
        transform: 0;
    }
    to {
        transform: rotate(-100deg) translateY(-10vw) rotate(100deg);
        transition-delay: 3s;
    }
}

@keyframes intro-horizontal-1 {
    from {
        transform: 0;
    }
    to {
        transform: translateY(-15vw);
        transition-delay: 1s;
    }
}

@keyframes intro-horizontal-2 {
    from {
        transform: 0;
    }
    to {
        transform: rotate(-40deg) translateY(-15vw) rotate(40deg);
        transition-delay: 2s;
    }
}

@keyframes intro-horizontal-3 {
    from {
        transform: 0;
    }
    to {
        transform: rotate(-80deg) translateY(-15vw) rotate(80deg);
        transition-delay: 3s;
    }
}

@keyframes intro-vertical-1 {
    from {
        transform: 0;
    }
    to {
        transform: translateY(-30vw);
        transition-delay: 1s;
    }
}

@keyframes intro-vertical-2 {
    from {
        transform: 0;
    }
    to {
        transform: rotate(-40deg) translateY(-30vw) rotate(40deg);
        transition-delay: 2s;
    }
}

@keyframes intro-vertical-3 {
    from {
        transform: 0;
    }
    to {
        transform: rotate(40deg) translateY(-30vw) rotate(-40deg);
        transition-delay: 3s;
    }
}
 
#menu {
    width: 25vh;
    height: 25vh;
    position: absolute;
    left: 88%;
    top: 75%;
    margin: -75px 0 0 -75px;
    list-style: none;
    font-size: 200%;
    text-align: center;
    justify-content: center;
}
 
.menu-button-light {
    width: 25vh;
    height: 25vh;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    background: #1976d2;
    background-size: 100%;
    overflow: hidden;
    text-decoration: none;
    opacity: 1;
    z-index: 3;
}

.menu-button-dark {
    width: 25vh;
    height: 25vh;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    background: #42a5f5;
    background-size: 100%;
    overflow: hidden;
    text-decoration: none;
    opacity: 1;
    z-index: 3;
}
 
.menu-item-light {
    width: 10vh;
    height: 10vh;
    position: absolute;
    left: 55%;
    line-height: 5px;
    top: 50%;
    margin: -50px 0 0 -50px;
    border-radius: 50%;
    background: #1976d2;
    transform: translate(0px, 0px);
    transition: transform 500ms;
    z-index: 2;
    transition: .5s;
    text-align: center;
}

.menu-item-dark {
    width: 10vh;
    height: 10vh;
    position: absolute;
    left: 55%;
    line-height: 5px;
    top: 50%;
    margin: -50px 0 0 -50px;
    border-radius: 50%;
    background: #42a5f5;
    transform: translate(0px, 0px);
    transition: transform 500ms;
    z-index: 2;
    transition: .5s;
    text-align: center;
}
 
.menu-item-light div {
    color: #fff;
    position: relative;
    top: 30%;
    left: 0;
    text-decoration: none;
}

.menu-item-dark div {
    color: #fff;
    position: relative;
    top: 30%;
    left: 0;
    text-decoration: none;
}

.menu-item-light:nth-child(2){
    animation-name: intro-1;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
}

.menu-item-light:nth-child(3){
    animation-name: intro-2;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.menu-item-light:nth-child(4){
    animation-name: intro-3;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
}

.menu-item-dark:nth-child(2){
    animation-name: intro-1;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
}

.menu-item-dark:nth-child(3){
    animation-name: intro-2;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.menu-item-dark:nth-child(4){
    animation-name: intro-3;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
}

#menu-tablet {
    width: 15vw;
    height: 15vw;
    margin: 0 10vw;
    position: absolute;
    left: 66%;
    top: 70%;
    list-style: none;
    font-size: 200%;
    text-align: center;
    justify-content: center;
    z-index: 2;
}
 
.menu-button-tablet-light {
    width: 15vw;
    height: 15vw;
    border-radius: 50%;
    background: #1976d2;
    background-size: 100%;
    overflow: hidden;
    text-decoration: none;
    opacity: 1;
    z-index: 3;
}
 
.menu-item-tablet-dark {
    width: 5vw;
    height: 5vw;
    line-height: 5px;
    position: absolute;
    top: 7vw;
    margin: 0 5vw;
    border-radius: 50%;
    background: #42a5f5;
    transform: translate(0px, 0px);
    transition: transform 500ms;
    z-index: -1;
    transition: .5s;
    text-align: center;
}
 
.menu-item-tablet-light div {
    padding-top: 1vw;
    color: #fff;
    text-decoration: none;
}

.menu-item-tablet-dark div {
    padding-top: 1vw;
    color: #fff;
    text-decoration: none;
}

.menu-item-tablet-light:nth-child(2){
    animation-name: intro-horizontal-1;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
}

.menu-item-tablet-light:nth-child(3){
    animation-name:intro-horizontal-2;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.menu-item-tablet-light:nth-child(4){
    animation-name: intro-horizontal-3;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
}

.menu-item-tablet-dark:nth-child(2){
    animation-name: intro-horizontal-1;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
}

.menu-item-tablet-dark:nth-child(3){
    animation-name:intro-horizontal-2;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.menu-item-tablet-dark:nth-child(4){
    animation-name: intro-horizontal-3;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
}

#menu-mobile {
    width: 30vw;
    height: 30vw;
    margin: 0 10vw;
    position: absolute;
    top: 70vh;
    left: 21vw;
    list-style: none;
    font-size: 200%;
    text-align: center;
    justify-content: center;
    z-index: 2;
}
 
.menu-button-mobile-light {
    width: 30vw;
    height: 30vw;
    border-radius: 50%;
    background: #1976d2;
    background-size: 100%;
    overflow: hidden;
    text-decoration: none;
    opacity: 1;
    z-index: 3;
}
 
.menu-item-mobile-dark {
    width: 15vw;
    height: 15vw;
    line-height: 5px;
    position: absolute;
    top: 7vw;
    margin: 0 8vw;
    border-radius: 50%;
    background: #42a5f5;
    transform: translate(0px, 0px);
    transition: transform 500ms;
    z-index: -1;
    transition: .5s;
    text-align: center;
}
 
.menu-item-mobile-light div {
    padding-top: 4vw;
    color: #fff;
    text-decoration: none;
}

.menu-item-mobile-dark div {
    padding-top: 4vw;
    color: #fff;
    text-decoration: none;
}

.menu-item-mobile-light:nth-child(2){
    animation-name: intro-vertical-1;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
}

.menu-item-mobile-light:nth-child(3){
    animation-name:intro-vertical-2;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.menu-item-mobile-light:nth-child(4){
    animation-name: intro-vertical-3;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
}

.menu-item-mobile-dark:nth-child(2){
    animation-name: intro-vertical-1;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
}

.menu-item-mobile-dark:nth-child(3){
    animation-name:intro-vertical-2;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.menu-item-mobile-dark:nth-child(4){
    animation-name: intro-vertical-3;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
}